import classNames from "classnames";
import { createLoader, error } from "../notifications";
import axios from "axios";
import PropTypes from "prop-types";
import { useState, useCallback, useRef } from "react";
import { route } from "../../../../helpers";
import { uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";
import Translate from "../Translate";

export default function ImageThumbnailFileInput(props) {
	const [images, setImages] = useState(props.image);

	const image = images.length ? images[0] : null;
	const inputRef = useRef(null);

	const handleChange = useCallback(
		image => {
			props.onChange(image);
			if (image.length > 0 && props.clearAfterUpload) {
				setImages([]);
			} else {
				setImages(image);
			}
		},
		[props],
	);

	const handleUpload = useCallback(
		async event => {
			const count = event.target.files.length;
			if (!count) {
				return;
			}

			if (props.onBeforeUpload) {
				props.onBeforeUpload();
			}

			let loader = null;
			if (props.useLoaders) {
				loader = createLoader(uctrans("file.file_uploading"));
			}

			if (count > props.maxFiles - images.length) {
				if (loader) {
					loader.failure(uctrans("file.too_many_files_selected"));
				}
				return;
			}

			let iteration = 0;
			let success = true;
			const uploadedImages = [];

			for (const file of event.target.files) {
				if (count > 1 && loader) {
					loader.update(uctrans("file.file_:number_of_:total_uploading", { number: ++iteration, total: count }));
				}

				try {
					const data = new FormData();
					data.append("file", file);
					data.append("public", props.public ? "1" : "0");
					data.append("type", `${props.small ? "small_" : ""}image`);
					data.append("append_show_url", "1");

					const result = await axios.post(route(props.storeRoute), data);
					if (result.data) {
						const uploadedFile = {
							id: result.data.id,
							filename: result.data.filename,
							id_token: result.data.id_token,
						};

						if (result.data.url) {
							Object.assign(uploadedFile, { url: result.data.url });
						}

						uploadedImages.push(uploadedFile);
					}
				} catch (exception) {
					if (exception.response && exception.response.data && exception.response.data.errors) {
						Object.values(exception.response.data.errors).forEach(errorMessage => {
							error(errorMessage);
						});
					} else {
						error(exception.message);
					}

					inputRef.current.value = "";

					success = false;
				}
			}

			if (success) {
				handleChange([...images, ...uploadedImages]);
				props.onAfterUpload && props.onAfterUpload(uploadedImages);
				if (loader) {
					loader.success(uctrans("file.file_uploading_complete"));
				}
			} else {
				if (loader) {
					loader.failure(uctrans("file.file_uploading_failed"));
				}
			}
		},
		[images, handleChange, props],
	);

	const deleteFile = useCallback(
		id => {
			handleChange(images.filter(file => file.id !== id));
		},
		[images, handleChange],
	);

	const inputProps = {};
	if (images.length < props.maxFiles - 1) {
		inputProps.multiple = true;
	}

	if (props.acceptedExtensions) {
		inputProps.accept = props.acceptedExtensions
			.map(extension => (extension.charAt(0) === "." ? extension : `.${extension}`))
			.join(",");
	}

	return (
		<>
			{image && (
				<div className="relative mt-2 p-2 shadow bg-white h-32 flex  justify-center">
					<img className="max-h-full self-center  border border-gray" src={image.url} alt={image.name} />

					{!props.disabled ? (
						<>
							<span
								onClick={() => deleteFile(image.id)}
								className="absolute top-0 right-0 bg-grey-darker opacity-75 p-1 pb-0 cursor-pointer">
								<EvaIcon type="trash-2-outline" width="24" height="24" fill="#de751f" />
							</span>

							{!image.object_id && !!props.newFileDownloadRoute ? (
								<a
									href={route(props.newFileDownloadRoute, image.id_token)}
									className="absolute right-0 bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
									<EvaIcon type="download-outline" width="24" height="24" fill="white" />
								</a>
							) : !!image.object_id && !!props.existingFileDownloadRoute ? (
								<a
									href={route(props.existingFileDownloadRoute, {
										...props.additionalFileDownloadRouteProps,
										image: image.id,
									})}
									className="absolute right-0 bottom-0 bg-grey-darker p-1 pb-0 opacity-75 cursor-pointer">
									<EvaIcon type="download-outline" width="24" height="24" fill="white" />
								</a>
							) : null}
						</>
					) : null}
				</div>
			)}

			{!image && (
				<div>
					<label
						className={classNames("button button-upload inline-block relative", props.disabled ? "disabled" : null)}>
						<input
							type="file"
							className="inputfile"
							{...inputProps}
							onInput={handleUpload}
							ref={inputRef}
							disabled={props.disabled}
						/>
						<EvaIcon type="file-add-outline" className="absolute left-0 ml-3" fill="#ffffff" height="20" width="20" />
						<Translate content="file.pick_a_file" />
					</label>
				</div>
			)}
		</>
	);
}

ImageThumbnailFileInput.propTypes = {
	storeRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	maxFiles: PropTypes.number,
	acceptedExtensions: PropTypes.array,
	additionalFileDownloadRouteProps: PropTypes.object,
	image: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			filename: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}),
	),
	public: PropTypes.bool,
	onChange: PropTypes.func,
	onBeforeUpload: PropTypes.func,
	onAfterUpload: PropTypes.func,
	clearAfterUpload: PropTypes.bool,
	useLoaders: PropTypes.bool,
	disabled: PropTypes.bool,
	small: PropTypes.bool,
};

ImageThumbnailFileInput.defaultProps = {
	maxFiles: 1,
	initialFiles: [],
	public: false,
	clearAfterUpload: false,
	useLoaders: true,
	disabled: false,
	additionalFileDownloadRouteProps: {},
	newFileDownloadRoute: "crm.files.download",
	storeRoute: "crm.files.store",
	small: false,
};
