import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState } from "react";
import DiscussRequestScore from "../../../../crm/js/react/components/discussRequests/scores/DiscussRequestScore";
import TextEditor from "../../../../crm/js/react/components/forms/textEditor/TextEditor";
import useGenericHandler from "../../hooks/useGenericHandler";
import useInputHandler from "../../hooks/useInputHandler";
import useSelectHandler from "../../hooks/useSelectHandler";
import { uctrans } from "../../../lib/Translator";
import AjaxForm from "../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../js/lib/Nav";
import { route } from "../../../helpers";
import { format } from "../../../lib/Date";
import FileInput from "../general/form/FileInput";
import LabeledTextInput from "../general/form/LabeledTextInput";
import Radio from "../general/form/radio/Radio";
import RadioGroup from "../general/form/radio/RadioGroup";
import IconButton from "../general/IconButton";
import Select from "../general/Select";
import Translate from "../general/Translate";

export default function DiscussRequestEditFormContent(props) {
	const [staticData] = useState(window.data.discuss_request_static_data);

	const [formData, setFormData] = useState({ ...window.data.discuss_request_values });
	const category = find(
		staticData.category_options,
		categoryOption => categoryOption.value === window.data.discuss_request_values.category_id,
	);

	const [finished, setFinished] = useState(window.data.discuss_request_values.finished);
	const [advice, setAdvice] = useState(
		find(
			staticData.advice_options,
			adviceOption => adviceOption.value === window.data.discuss_request_values.advice_id,
		),
	);

	const formType = window.data.discuss_request_formtype;
	const [loggedInAsContactperson] = useState(typeof window.data.contactperson !== "undefined");
	const [loggedInAsRelationmanager] = useState(staticData.is_relationmanager);
	const [showScore] = useState(staticData.show_score);
	const [disabled] = useState(staticData.edit_score_only || window.data.discuss_request_values.finished);

	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		Nav.go(staticData.overview_url);
	};

	const handleFormDataChange = (key, value) => {
		setFormData({
			...formData,
			[key]: value,
		});
	};

	const handleAdviceChange = adviceId => {
		setAdvice(find(staticData.advice_options, adviceOption => adviceOption.value === adviceId));
		handleFormDataChange("advice_id", adviceId);
	};
	const handleFinishedChange = finished => {
		setFinished(finished);
		handleFormDataChange("finished", finished);
	};

	return (
		<>
			<div className="flex justify-between flex-col-reverse lg:flex-row">
				<div>
					<h2 className="block mt-6 lg:mt-0 lg:inline-block">
						{uctrans("discuss_requests.singular")} {formData.number}
					</h2>
					<IconButton
						className="ml-6"
						href={route(staticData.pdfRouteKey, { discuss_request: formData.id })}
						content={uctrans("discuss_requests.download_as_pdf")}
						icon="download-outline"
						primary
					/>
					<IconButton
						className="ml-6"
						href={route(staticData.releaseLockRouteKey, { discuss_request: formData.id })}
						content={uctrans("discuss_requests.release_lock")}
						icon="unlock-outline"
					/>
				</div>

				<a className="link" href={staticData.overview_url}>
					{uctrans("general.back_to_overview_:page", {}, { page: "discuss_requests.plural" })}
				</a>
			</div>

			<AjaxForm
				method="PUT"
				submitUrl={route(props.updateRoute, formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "discuss_requests.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "discuss_requests.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div>
					<div className="form-container">
						<div className="form-1-4">
							<label htmlFor="treating_contactperson_id">
								<Translate content="discuss_requests.fields.datetime" />
							</label>
							{formData.datetime
								? uctrans("general.:date_at_:time", {
										date: format(formData.datetime, "dd-MM-y"),
										time: format(formData.datetime, "HH:mm"),
								  })
								: "-"}
						</div>
						<div className="form-1-4">
							<label htmlFor="status">
								<Translate content="discuss_requests.fields.status" />
							</label>
							<p>{formData.status_object ? formData.status_object.label : "-"}</p>
						</div>
					</div>

					<div className="form-container">
						{formType === "edit" && loggedInAsContactperson ? (
							<div className="form-1-2">
								<label htmlFor="treating_contactperson_id">
									<Translate content="discuss_requests.fields.treating_contactperson_id" />{" "}
									<span className="text-orange">*</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "general.colleague" })}
									value={staticData.contactperson_options.filter(
										({ value }) => Number(value) === Number(formData.treating_contactperson_id),
									)}
									options={staticData.contactperson_options}
									name="treating_contactperson_id"
									onChange={selectHandler}
									isClearable={false}
								/>
							</div>
						) : (
							<div className="form-1-2">
								<label htmlFor="treating_contactperson_id">
									<Translate content="discuss_requests.fields.treating_contactperson_id" />
								</label>
								{staticData.contactperson_options
									.filter(({ value }) => Number(value) === Number(formData.treating_contactperson_id))
									.map(data => (
										<p key={data.value}>
											{data.name} <br />
											<a className="link" href={`mailto:${data.email}`}>
												{data.email}
											</a>
										</p>
									))}
							</div>
						)}
						{formType === "edit" && loggedInAsContactperson ? (
							<div className="form-1-4">
								<LabeledTextInput
									label="discuss_requests.fields.phonenumber"
									value={formData.submitter_phone}
									name="submitter_phone"
									onChange={inputHandler}
									required
								/>
							</div>
						) : (
							<div className="form-1-4">
								<label htmlFor="treating_contactperson_id">
									<Translate content="discuss_requests.fields.phonenumber" />
								</label>
								<p>{formData.submitter_phone ? formData.submitter_phone : "-"}</p>
							</div>
						)}
					</div>

					<div className="form-container">
						<div className="form-full">
							<label htmlFor="category_id">
								<Translate content="discuss_requests.fields.category_id" />
							</label>
							<p>{category.label}</p>
						</div>
					</div>
					{!!(formType === "show" && finished) && (
						<div className="form-container">
							<div className="form-1-4">
								<label htmlFor="advice_id">
									<Translate content="discuss_requests.fields.advice_id" />
								</label>
								<p>{advice.label}</p>
							</div>
						</div>
					)}

					{!!(category && category.non_residents_policy && formType !== "show") && (
						<div
							className="text-xs italic mb-3"
							dangerouslySetInnerHTML={{ __html: staticData.non_residents_warning }}
						/>
					)}
					<br />
					<h3>{uctrans("discuss_requests.client_data")}</h3>

					<div className="form-container">
						<div className="form-1-4">
							<label htmlFor="client_name">
								<Translate content="discuss_requests.fields.last_name" />
							</label>
							{formData.client_name}
						</div>
						<div className="form-1-4">
							<label htmlFor="client_date_of_birth">
								<Translate content="discuss_requests.fields.date_of_birth" />
							</label>
							<div className="client_date_of_birth">
								{formData.client_date_of_birth ? format(formData.client_date_of_birth, "dd-MM-y") : "-"}
							</div>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-4">
							<label htmlFor="client_address_type">
								<Translate content="discuss_requests.fields.address_type" />
							</label>
							{staticData.address_type_options
								.filter(({ value }) => Number(value) === Number(formData.client_address_type))
								.map(data => (
									<p key={data.value}>{data.label}</p>
								))}
						</div>

						<div className="form-1-2">
							<label htmlFor="client_address">
								<Translate content="discuss_requests.fields.address" />
							</label>
							{formData.client_address ? (
								<>
									{formData.client_address.street} {formData.client_address.number} {formData.client_address.addition}
									<br />
									{formData.client_address.zipcode} {formData.client_address.city}
									{!!(category && category.non_residents_policy) && (
										<>
											<br />
											{formData.client_address.country}
										</>
									)}
								</>
							) : (
								"-"
							)}
						</div>
					</div>
					{!!(category && category.non_residents_policy) && (
						<>
							<div className="form-container">
								<div className="form-1-2">
									<label htmlFor="is_abn_client">
										<Translate content="discuss_requests.fields.is_abn_client" />
									</label>
									<p>
										{formData.is_abn_client === 1
											? uctrans("general.yes")
											: formData.is_abn_client === 0
											  ? uctrans("general.no")
											  : "-"}
									</p>
								</div>

								<div className="form-1-2">
									<label htmlFor="is_shell_buza">
										<Translate content="discuss_requests.fields.is_shell_buza" />
									</label>
									<p>
										{formData.is_shell_buza === 1
											? uctrans("general.yes")
											: formData.is_shell_buza === 0
											  ? uctrans("general.no")
											  : "-"}
									</p>
								</div>
							</div>
							<div className="form-container">
								<div className="form-1-2">
									<label htmlFor="housebank_discount_desirable">
										<Translate content="discuss_requests.fields.housebank_discount_desirable" />
									</label>
									<p>
										{formData.housebank_discount_desirable === 1
											? uctrans("general.yes")
											: formData.housebank_discount_desirable === 0
											  ? uctrans("general.no")
											  : "-"}
									</p>
								</div>
							</div>
						</>
					)}
					<h3>{uctrans("general.description")}</h3>
					<div className="form-container">
						<div className="form-full">
							<label htmlFor="description">
								<Translate content="discuss_requests.fields.description" />
							</label>
							{formData.description ? formData.description : "-"}
						</div>
					</div>

					<div className="form-container">
						<div className="form-full">
							<label htmlFor="explanation">
								<Translate content="discuss_requests.fields.explanation" />
							</label>
							{formData.explanation ? formData.explanation : "-"}
						</div>
					</div>

					<h3>{uctrans("discuss_requests.attachments")}</h3>
					<div className="form-container mb-3">
						<div className="form-1-2">
							{formType !== "show" && loggedInAsContactperson ? (
								<FileInput
									storeRoute={props.fileStoreRoute}
									newFileDownloadRoute={props.newFileDownloadRoute}
									existingFileDownloadRoute={props.existingFileDownloadRoute}
									initialFiles={formData.attachments}
									maxFiles={100}
									onChange={files => genericHandler(files, "attachments")}
								/>
							) : (
								!!formData.attachments &&
								formData.attachments.map(attachement => (
									<div key={attachement.id} className=" border border-grey mb-2 p-3 flex justify-between">
										{!!attachement.object_id && !!props.existingFileDownloadRoute ? (
											<a className="link" href={route(props.existingFileDownloadRoute, attachement.id)}>
												{attachement.filename}
											</a>
										) : (
											<span>{attachement.filename}</span>
										)}
									</div>
								))
							)}
						</div>
					</div>

					<h3>{uctrans("discuss_requests.reactions.plural")}</h3>

					{map(formData.reactions, reaction => (
						<div key={`reaction_${reaction.id}`} className="form-container border-b">
							<div className="form-full">
								<label>
									{reaction.datetime
										? uctrans("general.on_:date_at_:time_by_:who", {
												date: format(reaction.datetime, "dd-MM-y"),
												time: format(reaction.datetime, "HH:mm"),
												who: reaction.actor && typeof reaction.actor.label !== "undefined" ? reaction.actor.label : "-",
										  })
										: "-"}
								</label>
								<div className="html-content" dangerouslySetInnerHTML={{ __html: reaction.content }} />
							</div>
						</div>
					))}

					{formType !== "show" ? (
						<div className="form-container">
							<div className="form-full">
								<label>
									<Translate content="discuss_requests.reactions.add_reaction" />
								</label>
								<TextEditor
									type="basic"
									disabled={disabled}
									value={formData.new_reaction_content}
									onChange={value => {
										genericHandler(value, "new_reaction_content");
									}}
								/>
							</div>
						</div>
					) : null}

					{formType !== "show" && !loggedInAsContactperson && (
						<>
							<h3>{uctrans("discuss_requests.handling")}</h3>
							<div className="form-container">
								<div className="form-full">
									<label htmlFor="finished">
										<Translate content="discuss_requests.fields.finished" />
									</label>
									<RadioGroup name="finished" disabled={disabled} value={finished} onChange={handleFinishedChange}>
										<Radio label={uctrans("general.no")} value={0} />
										<Radio label={uctrans("general.yes")} value={1} />
									</RadioGroup>
								</div>
							</div>
							{!!finished && (
								<>
									<div className="form-container">
										<div className="form-full">
											<label htmlFor="advice_id">
												<Translate content="discuss_requests.fields.advice" /> <span className="text-orange">*</span>
											</label>
											<RadioGroup
												name="advice_id"
												disabled={disabled}
												value={formData.advice_id}
												onChange={handleAdviceChange}>
												{staticData.advice_options.map(advice => (
													<Radio label={advice.label} value={advice.value} key={advice.value} />
												))}
											</RadioGroup>
										</div>
									</div>

									{typeof advice !== "undefined" && (
										<>
											<div
												className="text-xs italic mb-3 form-1-2"
												dangerouslySetInnerHTML={{ __html: staticData.mid_office_disclaimer }}
											/>
											{!!(
												advice.positive &&
												category.non_residents_policy &&
												formData.housebank_discount_desirable === 1
											) && (
												<div className="form-container">
													<div className="form-full">
														<label htmlFor="housebank_discount_possible">
															<Translate content="discuss_requests.fields.housebank_discount_possible" />{" "}
															<span className="text-orange">*</span>
														</label>
														<RadioGroup
															disabled={disabled}
															name="housebank_discount_possible"
															value={formData.housebank_discount_possible}
															onChange={value => handleFormDataChange("housebank_discount_possible", value)}>
															<Radio label={uctrans("general.yes")} value={1} />
															<Radio label={uctrans("general.no")} value={0} />
														</RadioGroup>
													</div>
												</div>
											)}
										</>
									)}
								</>
							)}
						</>
					)}

					{!!(finished && loggedInAsContactperson) && (
						<div className="text-xs italic mb-3" dangerouslySetInnerHTML={{ __html: staticData.finished_message }} />
					)}

					{showScore && !staticData.is_website && (
						<DiscussRequestScore
							initialScore={formData.score}
							onUpdate={score => handleFormDataChange("score", score)}
							viewedByRelationmanager={loggedInAsRelationmanager}
						/>
					)}

					{formType !== "show" && (
						<div className="form-container">
							<div className="form-full">
								<div className="submit-bar">
									<div className="submit-container">
										<button className="button background-color-success button-primary" type="submit">
											<span>
												{loggedInAsContactperson
													? uctrans("discuss_requests.save_and_send_to_mid_office")
													: uctrans("general.save")}
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</AjaxForm>
		</>
	);
}

DiscussRequestEditFormContent.propTypes = {
	fileStoreRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	updateRoute: PropTypes.string,
};
