import axios from "axios";
import PropTypes from "prop-types";
import { forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageContext } from "../../../../crm/js/react/PageContext";
import Nav from "../../../lib/Nav";
import { trans } from "../../../lib/Translator";
import { createLoader, error, queueNotification, warning } from "./notifications";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import ConfirmDialog from "./ConfirmDialog";
import { router } from "@inertiajs/react";

// eslint-disable-next-line prefer-arrow-callback
const AjaxForm = forwardRef(function AjaxForm(props, ref) {
	const [isOpen, setIsOpen] = useState(false);
	const [pending, setIsPending] = useState(false);
	const [method] = useState(props.method || "POST");
	const formRef = useRef(null);
	const { isInertia } = useContext(PageContext);

	const exportableProps = useMemo(
		() =>
			["className", "style", "id", "action"].reduce((acc, prop) => {
				if (props[prop]) {
					acc[prop] = props[prop];
				}

				return acc;
			}, {}),
		[props],
	);

	const handleInertiaForm = event => {
		event.preventDefault();

		// only submit one at a time
		if (pending || !props.submitUrl) {
			return;
		}

		setIsPending(true);

		let loader;
		if (props.loaderText) {
			loader = createLoader(props.loaderText);
		}

		router.visit(props.submitUrl, {
			preserveState: "errors",
			method: props.method,
			data: collectData(),
			onError: errors => loader.displayErrors(errors),
			onSuccess: () => loader.success(props.successText),
			onFinish: setIsPending(false),
		});
	};

	const handleForm = async event => {
		if (event) {
			if (formRef.current !== event.target) {
				// not our ajax form -> cancel the event
				return;
			}

			event.preventDefault();
		}

		if (props.showConfirm.when && !isOpen) {
			setIsOpen(true);

			event.preventDefault();
			return;
		}

		if (pending || !props.submitUrl) {
			return;
		}

		setIsPending(true);

		let loader;
		if (props.loaderText) {
			loader = createLoader(props.loaderText);
		}

		try {
			const result = await axios({
				method,
				url: props.submitUrl,
				data: collectData(),
			});

			if (result) {
				handleSuccess(result, loader);
			} else {
				if (loader) {
					loader.destroy();
				}

				/**
				 * Used an errorcode to trace this error when needed
				 */
				handleError(`${trans("Onbekende fout")}(0X800)`, loader);
			}
		} catch (error) {
			handleError(error, loader);
		}
	};

	const handleError = (e, loader = null) => {
		setIsPending(false);

		let first = true;
		if (e.response && e.response.status === 422) {
			if (Object.prototype.hasOwnProperty.call(e.response.data, "errors")) {
				forEach(e.response.data.errors, message => {
					if (first && loader) {
						loader.failure(upperFirst(message[0]));
						first = false;
					} else {
						error(upperFirst(message[0]));
					}
				});
			}

			if (props.onError) {
				props.onError(e);
			}
		} else {
			if (props.onError) {
				props.onError(e);
				loader.destroy();
			} else {
				if (e.message && loader) {
					loader.failure(e.message);
				} else if (e.message) {
					error(e.message);
				}

				console.error(e);
			}
		}
	};

	const handleSuccess = (response, loader = null) => {
		setIsPending(false);

		if (response.data && response.data.warnings) {
			let first = true;
			forEach(response.data.warnings, warningText => {
				if (first && loader) {
					warning(upperFirst(warningText));
					first = false;
				} else {
					warning(upperFirst(warningText));
				}
			});
		}

		if (loader && props.successText) {
			if (props.useFlashMessage) {
				loader.destroy();
				queueNotification("success", props.successText, null);
			} else {
				loader.success(props.successText);
			}
		} else if (loader) {
			loader.destroy();
		}

		if (props.successUrl) {
			Nav.go(props.successUrl);
		}

		if (props.onSuccess) {
			props.onSuccess(response);
		}
	};

	const collectData = () => {
		if (props.data) {
			return props.data;
		} else {
			const data = {};

			Object.values(formRef.current).forEach(element => {
				if (element.name) {
					data[element.name] = element.value;
				}
			});

			return data;
		}
	};

	useImperativeHandle(ref, () => ({
		handleForm,
	}));

	const handleSubmit = e => {
		if (isInertia) {
			handleInertiaForm(e);
		} else {
			handleForm(e);
		}
	};

	return (
		<>
			<form ref={formRef} {...exportableProps} onSubmit={e => handleSubmit(e)}>
				{props.children}
			</form>
			<ConfirmDialog
				onConfirm={() => {
					setIsOpen(false);
					formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
				}}
				confirmIsDisabled={props.showConfirm.blocking}
				cancelButtonLabel={props.showConfirm.cancelText}
				confirmButtonLabel={props.showConfirm.confirmText}
				title={props.showConfirm.title}
				onCancel={() => setIsOpen(false)}
				isOpen={isOpen}>
				{props.showConfirm.description}
			</ConfirmDialog>
		</>
	);
});

export default AjaxForm;

AjaxForm.propTypes = {
	method: PropTypes.string,
	submitUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	successUrl: PropTypes.string,
	children: PropTypes.node.isRequired,
	data: PropTypes.object,

	loaderText: PropTypes.string,
	successText: PropTypes.string,

	onError: PropTypes.func,
	onSuccess: PropTypes.func,
	onSubmit: PropTypes.func,

	useFlashMessage: PropTypes.bool,
	showConfirm: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			when: PropTypes.bool,
			confirmText: PropTypes.string,
			cancelText: PropTypes.string,
			title: PropTypes.string,
			description: PropTypes.node,
			blocking: PropTypes.bool,
		}),
	]),
};

AjaxForm.defaultProps = {
	useFlashMessage: false,
	showConfirm: false,
};
